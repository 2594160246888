<template>
    <div class="base">
        <main class="main-body">
            <div class="security-body" id="resetPW-body">
                <router-link to="/login">
                  <div class="text-center">
                    <img :src="require('../assets/images/horizontal_logo.png')" width="250" height="50">
                  </div>        
                </router-link>

                <div class="text-center mt-3 mb-2 title">{{$t("FORGOT PASSWORD")}}</div>

                <form id="resetpassword" @submit.stop.prevent="forgotPassword">
                    <div class="form-group">
                        <label for="email">{{$t("Email")}}</label>
                        <b-form-input
                          id="example-input-1"
                          :placeholder="$t(`Enter email`)"
                          name="example-input-1"
                          v-model="$v.form.email.$model"
                          :state="validateState('email')"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{$t("This is a required field.")}}</b-form-invalid-feedback>
                        <!-- <input type="text" class="form-control" id="email" placeholder="Enter Email" v-model="email"> -->
                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button type="submit" class="security-btn">{{$t("Submit")}}</button>
                    </div>
                </form>
            </div>
        </main>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import api from '../helper/api.js';
 

export default {
  name:"ForgotPassword",
  data() {
    return {
        form:{
            email:""
        }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async forgotPassword(){
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
            this.isClick = false;
            return;
        }
        
        let params = {
          email : this.form.email,
        }

        try{
          await api.apiCall('core','POST','/operators/forget-password-email',params);
          this.$swal({
              text: this.$t("Send email success"),
              icon: 'success',
          });
          window.location.reload();
        }catch(error){
          this.$swal({
              text: error.message,
              icon: 'error'
          });
        }
    }
  }
};
</script>

<style>
@import '../css/styles.css';
#resetPW-body {
  margin: 5% 10%;
  width: 40%;
}

@media only screen and (max-width: 768px) {
  #resetPW-body {
    margin:10% 5%;
    width: 100%;
  }
}
</style>